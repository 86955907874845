
html, body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: white; /* Matches the light theme on the ZipBox site */
    margin: 0;
    padding: 0px;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    min-height: 100%;
    scroll-behavior: smooth; /* This will smooth out scroll, which might help with the experience */

}
.form {
    color: black; 
    display: flex;
    justify-content: center;
    align-items: center;
   /* For mobile view */

  }
  
  @media (max-width: 768px) {
    .inputform {
      margin-bottom: 20px; /* Adjust the margin value as needed */
    }
}

.fullform {
    text-align: center;
    max-width: 600px;
    background-color: #D73301; 
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 50px;
    align-items: center;
    
}



.fullform h1{
    color: white;
}

.button1 {

    font-size: 1rem;
    padding: 10px 20px;
    background-color: #333333;
    color: #ffffff;
    border: none;
    border-radius: 0 5px 5px 0; /* Round right corners */
    cursor: pointer;
    transition: background-color 0.3s ease;
    

}

.button1:hover {
    background-color: #444444; /* Slightly darker on hover */
}

.inputform {
    font-size: 1rem;
    padding: 10px;
    border: 1px solid #ddd;
    border-right: none; /* Removes border between input and button */
    border-radius: 5px 0 0 5px; /* Round left corners */
    outline: none;
    width: 300px;
   
}
  
.order-details {
    color: white;
}

.tracking-number {
    color: white;
}

/* Tracking Points styling */
.tracking-points {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f1f1f1;
}

.tracking-points p {
    font-size: 0.9rem;
    margin: 8px 0;
}

/* Timestamp styling */
.time-stamp {
    color: #666;
}


.logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0; /* Add padding above and below the logo */
    background-color: #ffffff; /* Optional: Set background color if needed */
    box-sizing: border-box;
}

/* Logo image styling */
.logo {
    max-width: 150px; /* Adjust size as needed */
    height: auto;
}

.page-container {

    max-width: 600px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 20px auto;
}

.p-tag {
    color: black; 
    font-size: 0.7rem;
    margin-top: 30px;
    text-decoration: underline;
}


.window {
    font-size: x-large;
}